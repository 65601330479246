<template>
  <div>
    <div class="px-3 py-2">
      <b-row class="align-items-center">
        <b-col sm="4">
          <b-input-group>
            <b-input-group-prepend>
              <b-input-group-text>
                <i class="icon-calendar"></i>
              </b-input-group-text>
            </b-input-group-prepend>
            <b-form-input type="month"
                          class="form-control"
                          v-model="serverParams.selectedMonth" />
          </b-input-group>
        </b-col>
        <b-col sm="4">
          <BuildingDropdown :server-params="serverParams" :on-select="getAccounts"/>
        </b-col>
        <b-col sm="4">
          <treeselect v-model="serverParams.account"
                      placeholder="Select Account"
                      :options="options.accounts" />
        </b-col>
        <b-col sm="4" class="mt-2">
          <b-button variant="primary" @click="onChange">View Report</b-button>
          <b-button class="ml-2" variant="primary" @click="onExport">Export Report</b-button>
        </b-col>
      </b-row>
    </div>
  </div>
</template>

<script>
  import BuildingDropdown from "@/components/common/BuildingDropdown";

  export default {
    data: () => ({
      options: {
        accounts: []
      }
    }),
    props: {
      serverParams: Object,
    },
    components: {
      BuildingDropdown,
    },
    created() {
      this.getAccounts();
    },
    methods: {
      onChange() {
        this.$emit("onChange", this.serverParams);
      },
      onExport() {
        this.$emit("onExport", this.serverParams);
      },
      getAccounts() {
        const self = this;
        self.$store
          .dispatch("apis/get", {
            url: "/account",
            params: self.serverParams,
          })
          .then((response) => {
            if (response.error) {
              self.$message.error({
                zIndex: 10000,
                message: response.message,
              });
            } else {
              self.options.accounts = response.data.data.map((x) => ({
                id: x.accountId,
                label: `${x.accountId}# ${x.name}`,
              }));
            }
          });
      },
    },
  };
</script>
