<template>
  <div class="animated fadeIn">
    <CardTable :title="title"
               :columns="table.columns"
               :rows="table.rows"
               :isLoading="table.isLoading"
               :pageSize="serverParams.pageSize"
               :pageNumber="serverParams.pageNumber"
               :totalPage="table.totalPage"
               :totalRecords="table.totalRecords"
               :pagination="true"
               :stickyHeader="`${tableHeight(300)}px`"
               @onPageChange="onPageChange">
      <template #filter>
        <SectionFilter :serverParams="serverParams" @onChange="getAll" @onExport="exportAll" />
      </template>
      <template #cell(deposit)="data">
        {{ numberFormat(data.item.deposit) }}
      </template>
      <template #cell(totalOutstandingAmount)="data">
        {{ numberFormat(data.item.totalOutstandingAmount) }}
      </template>
      <template #cell(overdueAmount)="data">
        {{ numberFormat(data.item.overdueAmount) }}
      </template>
      <template #cell(currentAmount)="data">
        {{ numberFormat(data.item.currentAmount) }}
      </template>
      <template #custom-foot style="inset-block-end: 0;">
        <b-th class="text-center" colspan="4">TOTAL</b-th>
        <b-th class="text-right">{{ numberFormat(table.summary.deposit) }}</b-th>
        <b-th class="text-right">{{ numberFormat(table.summary.usageKwh) }}</b-th>
        <b-th class="text-right">{{ numberFormat(table.summary.usageCharges) }}</b-th>
        <b-th class="text-right">{{ numberFormat(table.summary.gstIncurred) }}</b-th>
        <b-th class="text-right">{{ numberFormat(table.summary.latePaymentFees) }}</b-th>
        <b-th class="text-right">{{ numberFormat(table.summary.additionalDepositRequired) }}</b-th>
        <b-th class="text-right">{{ numberFormat(table.summary.adjustmentAmount) }}</b-th>
        <b-th class="text-right">{{ numberFormat(table.summary.amount) }}</b-th>
      </template>
    </CardTable>
  </div>
</template>

<style>
  tfoot,
  tfoot th,
  tfoot td {
    position: -webkit-sticky;
    position: sticky;
    bottom: 0;
    background: #fff;
    z-index: 4;
  }

    tfoot th:first-child {
      z-index: 5;
    }
</style>

<script>
  import { reportMonthlyAccountDetails as columns } from "@/shared/columns";
  import { getDate, numberFormat, tableHeight } from "@/shared/utils";
  import SectionFilter from "./SectionFilter.vue";
  export default {
    components: {
      SectionFilter,
    },
    data: () => ({
      title: "Monthly Account Details Report",
      serverParams: {
        pageNumber: 1,
        pageSize: 400,
        selectedMonth: getDate("YYYY-MM"),
      },
      table: {
        isLoading: true,
        columns,
        rows: [],
        totalPage: 0,
        totalRecords: 0,
      },
    }),
    created() {
      const self = this;
      self.getAll();
    },
    methods: {
      numberFormat,
      tableHeight,
      updateParams(newProps) {
        this.serverParams = Object.assign({}, this.serverParams, newProps);
      },
      onPageChange(params) {
        this.updateParams({ pageNumber: params });
        this.getAll();
      },
      getAll() {
        const self = this;

        self.table.isLoading = true;
        self.$store
          .dispatch("apis/get", {
            url: "/report/monthly-account-details",
            params: self.serverParams,
          })
          .then((response) => {
            if (response.error) {
              self.$message.error({
                zIndex: 10000,
                message: response.message,
              });
            } else {
              self.table.rows = response.data.data;
              self.table.summary = response.data.additionalData;
              self.table.totalPage = response.data.totalPage;
              self.table.totalRecords = response.data.totalData;
            }
            self.table.isLoading = false;
          });
      },
      exportAll() {
        const self = this;

        self.$store
          .dispatch("apis/download", {
            url: "/report/monthly-account-details/export",
            params: self.serverParams,
          })
          .then((response) => {
            console.log(response)
            if (response.error) {
              self.$message.error({
                zIndex: 10000,
                message: response.message,
              });
            } else {
              let fileURL = window.URL.createObjectURL(new Blob([response])),
                fileLink = document.createElement("a");

              fileLink.href = fileURL;
              fileLink.setAttribute("download", "monthly-account-details.xls");
              document.body.appendChild(fileLink);

              fileLink.click();
            }
          });
      },
    },
  };
</script>
